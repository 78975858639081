@use '../../../styles/mixins';

.container {
  position: fixed;
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  backface-visibility: hidden;
  z-index: 1002;
  width: 45px;
  height: 45px;
  z-index: 1600;
  @include mixins.mq(tablet) {
    right: 30px;
    width: 55px;
    height: 55px;
  }
  @include mixins.mq(desktop) {
    right: 60px;
  }
}

.circleButton {
  border: none;
  border-radius: 50%;
  background: #c53e9e;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(197, 62, 158, 0.3);
  animation: pulse 3s infinite;
  width: 45px;
  height: 45px;
  @include mixins.mq(tablet) {
    width: 55px;
    height: 55px;
  }
}

.circleButton:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@keyframes vibrating {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

.iconWrapper.vibrating {
  animation: vibrating 0.3s infinite;
}
