@use '../../styles/mixins';
.footer {
  width: 100%;
  background: var(--background-color);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    @include mixins.mq(tablet) {
      flex-direction: row;
      margin-top: 30px;
      margin-bottom: 30px;
      justify-content: space-around;
      gap: 20px;
    }
    @include mixins.mq(desktop) {
      flex-direction: row;
      margin-top: 40px;
      margin-bottom: 40px;
      justify-content: space-between;
      gap: 170px;
    }
  }

  &__partAddress {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include mixins.mq(tablet) {
      width: 35%;
    }
    @include mixins.mq(desktop) {
      width: 40%;
    }
  }

  &__partMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include mixins.mq(tablet) {
      width: 20%;
    }
    @include mixins.mq(desktop) {
      width: 25%;
    }
  }

  &__partSocial {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include mixins.mq(tablet) {
      align-items: start;
      width: 45%;
    }
    @include mixins.mq(desktop) {
      width: 35%;
    }
  }

  &__partLogo {
    display: flex;
    align-items: left;
    margin-bottom: 20px;
  }

  &__partS {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  &__partTitle {
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.2;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 21px;
      line-height: 1.2;
    }
    @include mixins.mq(desktop) {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  &__partSotialIcon {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__part {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 16px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__linkS {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 16px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: auto;
  }

  &__text {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 16px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__textP {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 16px;
    }
    @include mixins.mq(desktop) {
      font-size: 18px;
    }
  }

  &__textContacts {
    margin-left: 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--text-color);
    @include mixins.mq(tablet) {
      font-size: 13px;
    }
    @include mixins.mq(desktop) {
      margin-left: 10px;
      font-size: 15px;
    }
  }

  &__link:hover,
  &__link:focus {
    color: var(--accent-color);
    transition: border-color 0.3s ease-out;
  }

  &__linkS:hover,
  &__linkS:focus {
    color: var(--accent-color);
    transition: border-color 0.3s ease-out;
  }
}

.footer__part::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.footer__part:hover::after {
  opacity: 1;
}
