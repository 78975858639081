@use '../../../styles/mixins';
.navigation {
  &__content {
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @include mixins.mq(tablet) {
      padding: 10px 0px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    @include mixins.mq(desktop) {
      gap: 30px;
    }
  }
}

.link {
  color: var(--text-color);
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  white-space: nowrap;
  @include mixins.mq(tablet) {
    font-size: 16px;
  }
  @include mixins.mq(desktop) {
    font-size: 20px;
  }
}

.active {
  color: var(--accent-color);
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.active.link:before {
  content: '';
  background: var(--accent-color);
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  transition: all 0.3s ease-in-out;
  @include mixins.mq(tablet) {
    height: 2px;
  }
}

.link:before {
  content: '';
  background: var(--accent-color);
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease-in-out;
  @include mixins.mq(tablet) {
    height: 2px;
  }
}

.link:hover {
  background-position: 0;
}

.link:hover::before {
  width: 100%;
}
