@use '../../../styles/mixins';

.btnDark {
  padding: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: var(--main-color);
  border: 1px solid var(--accent-color);
  color: var(--accent-color);
  border-radius: 10.3108px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  transition: background-color var(--transition-duration) var(--timing-function),
    color var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function);
  &:hover {
    background-color: var(--background-btn-second-color);
    border-color: var(--background-btn-second-color);
    box-shadow: var(--hover-box-shadow);
    color: var(--btn-text-color);
  }
  @include mixins.mq(tablet) {
    width: 190px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  @include mixins.mq(desktop) {
    width: 230px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}

.btnSchoolLight {
  padding: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: var(--main-color);
  border: 1px solid var(--accent-color-second);
  color: var(--accent-color-second);
  border-radius: 10.3108px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  transition: background-color var(--transition-duration) var(--timing-function),
    color var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function);
  &:hover {
    background-color: var(--accent-color-second);
    border-color: var(--accent-color-second);
    box-shadow: var(--hover-box-shadow-school);
    color: var(--main-color);
  }
  @include mixins.mq(tablet) {
    width: 190px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  @include mixins.mq(desktop) {
    width: 230px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}

.btnSchoolDark {
  padding: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: var(--accent-color-second);
  border: 1px solid var(--main-color);
  color: var(--main-color);
  border-radius: 10.3108px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  transition: background-color var(--transition-duration) var(--timing-function),
    color var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function);
  &:hover {
    background-color: var(--main-color);
    border-color: var(--accent-color-second);
    box-shadow: var(--hover-box-shadow-school);
    color: var(--accent-color-second);
  }
  @include mixins.mq(tablet) {
    width: 190px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  @include mixins.mq(desktop) {
    width: 230px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}

.btnLight {
  padding: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: var(--accent-color);
  border: 1px solid var(--accent-color);
  color: var(--btn-text-color);
  border-radius: 10.3108px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  transition: background-color var(--transition-duration) var(--timing-function),
    color var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function);
  &:hover {
    background-color: var(--background-btn-second-color);
    border-color: var(--background-btn-second-color);
    box-shadow: var(--hover-box-shadow);
    color: var(--btn-text-color);
  }
  @include mixins.mq(tablet) {
    width: 190px;
    height: 40px;
    font-size: 15px;
    line-height: 20px;
  }
  @include mixins.mq(desktop) {
    width: 230px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}

.exitHeaderBtn {
  background-color: transparent;
  display: inline-flex;
  align-items: baseline;
  font-size: 15px;
  color: var(--text-color);
  @include mixins.mq(tablet) {
    font-size: 16px;
  }
  @include mixins.mq(desktop) {
    font-size: 18px;
  }
}

.btnSchoolCampDark {
  padding: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: #fd821f;
  border: 1px solid #fd821f;
  color: var(--main-color);
  border-radius: 10.3108px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  transition: background-color var(--transition-duration) var(--timing-function),
    color var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function);
  &:hover {
    background-color: var(--main-color);
    border-color: #fd821f;
    box-shadow: var(--hover-box-shadow-school-camp);
    color: var(--text-color);
  }
  @include mixins.mq(tablet) {
    width: 190px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
  @include mixins.mq(desktop) {
    width: 230px;
    height: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}
