@use '../../../styles/mixins';

.sotialNetwork {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;

  &__part {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__img {
    display: inline-block;
    vertical-align: middle;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    @include mixins.mq(tablet) {
      width: 27px !important;
      height: 27px !important;
    }
    @include mixins.mq(desktop) {
      width: 30px !important;
      height: 30px !important;
    }
  }

  &__text {
    font-weight: 200;
    font-size: 14px;
    color: var(--second-text-color);
    @include mixins.mq(tablet) {
      font-size: 15px;
    }
    @include mixins.mq(desktop) {
      font-size: 16px;
    }
  }
}

.add {
  width: 33px !important;
  height: 33px !important;
  border-radius: 50%;
  @include mixins.mq(tablet) {
    width: 30px !important;
    height: 30px !important;
  }
  @include mixins.mq(desktop) {
    width: 33px !important;
    height: 33px !important;
  }
}

.sotialNetwork__part,
.aboutPanel__part {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sotialNetwork__part:after,
.aboutPanel__part:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.243);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sotialNetwork__part:hover:after,
.aboutPanel__part:hover:after {
  opacity: 1;
}

.facebookPart {
  position: relative;
}

.subMenu {
  position: absolute;
  top: 65%;
  left: -20px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  z-index: 100;
}

.subMenu a {
  color: var(--text-color);
  text-decoration: none;
  padding: 5px;
}

.subMenu a:hover {
  color: var(--accent-color);
}
